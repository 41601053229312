import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../utils/constant';
import { urls } from '../../utils/urls';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public sendBrowserInfo(browser: string, version: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const url = urls.final.statistics
      .replace('{navigator}', browser)
      .replace('{version}', version);
    return this._http.post(this._appConfig.getConfig().baseUrl + url, null, {
      headers,
    });
  }
}
