import { StateInterface } from './interface/state.interface';
import { PermissionModel } from './model/permission.model';
import { DataParamsModel } from './model/DataParams.model';
import { ToastModel } from './model/toast.model';
import { DetailOfRoomModel } from './model/DetailOfRoom.model';

export const ROOT = 'config';

export interface RootState {
  [ROOT]: StateInterface;
}

export const initState: StateInterface = {
  camera: new PermissionModel(),
  microphone: new PermissionModel(),
  browser: '',
  modalStatus: false,
  audioInputDevices: [],
  audioOutputDevices: [],
  videoDevices: [],
  so: '',
  dataParams: new DataParamsModel(),
  groupRooms: [new DataParamsModel()],
  idRoom: '',
  errorMessages: [],
  stepTutorial: 0,
  showError: false,
  toast: new ToastModel(),
  detailOfRoom: new DetailOfRoomModel(),
  isTest: false,
  statusRecording: false
};
