import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urls } from '../../utils/urls';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config/app-config.service';
import { Constant } from '../../utils/constant';
import { DetailOfRoomInterface } from '../../interfaces/detail-of-room.interface';

@Injectable({
  providedIn: 'root',
})
export class GetConfigurationService {
  constructor(
    private readonly _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public getDataCenterResponse(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*/*',
      Accept: '*/*',
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get(
      this._appConfig.getConfig().baseUrl + urls.final.centerResponse,
      { headers }
    );
  }

  public getDataParams(params: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get(
      this._appConfig.getConfig().baseUrl + urls.final.dataParams + params,
      { headers }
    );
  }

  public patchDataParams(params: string, state: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.patch(
      this._appConfig.getConfig().baseUrl + urls.final.dataParams + params,
      { state },
      { headers }
    );
  }

  public getConfigurationDetailRoom(
    roomClient: string
  ): Observable<DetailOfRoomInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<DetailOfRoomInterface>(
      this._appConfig.getConfig().baseUrl +
        urls.final.configurationDetail +
        roomClient,
      { headers }
    );
  }
}
