import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AnswerRecordingService } from './answer-recording.service';

@Component({
  selector: 'app-answer-recording',
  templateUrl: './answer-recording.component.html',
  styleUrls: ['./answer-recording.component.scss']
})
export class AnswerRecordingComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private answerRecordingService: AnswerRecordingService
  ) { }

  ngOnInit(): void {
  }

  public onConfirm() {
    this.modalService.hide();
    this.answerRecordingService.sendClickEvent();
    // return of(true);
  }

}
