import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromLoginSelectors from '../app/store/login/login.selector';
import { AppConfigService } from './services/app-config/app-config.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  readonly isAuthenticated$: Observable<boolean> = this._store.select(
    fromLoginSelectors.logged
  );
  constructor(
    private readonly _store: Store,
    private readonly router: Router,
    private appConfig: AppConfigService
  ) {}

  canActivate(): Observable<boolean> {
    if (this.appConfig.getConfig().requireLogin) {
      return this.isAuthenticated$.pipe(
        tap((authenticated) => {
          if (!authenticated) {
            this.router.navigate(['/login']);
          }
        })
      );
    } else {
      return of(true);
    }
  }
}
