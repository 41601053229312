import { Injectable } from '@angular/core';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import * as fromLoginActions from '../../store/login/login.action';

@Injectable()
export class LoginEffect {
  action: any;

  readonly loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLoginActions.login),
      exhaustMap(({ username, password }) =>
        this.loginService
          .login({
            username,
            password,
          })
          .pipe(
            map((res) => {
              if (res) {
                this.action = fromLoginActions.loginSuccess({ user: username });
              } else {
                this.action = fromLoginActions.loginFailed();
              }
            }),
            // map((token) => this.mapTokenToLoginResponse(token)),
            concatMap(() => [this.action]),
            catchError((error) => {
              console.log(error);
              return of(fromLoginActions.loginFailed(/*{ error }*/));
            })
          )
      )
    )
  );

  readonly logoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLoginActions.logout),
      concatMap(() => [fromLoginActions.removeUser()]),
      tap(() => this.redirectToLogin())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly loginService: LoginService,
    private readonly router: Router
  ) {}

  private redirectToLogin(): void {
    if (this.router.url.includes('rooms')) {
      this.router.navigate(['/login']);
    }
  }
}
