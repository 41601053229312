<div class="main-container">
  <router-outlet></router-outlet>

  <footer>
    <a href="https://www.tecnologiasplexus.com/" target="_blank" aria-label="https://www.tecnologiasplexus.com/">
      <img src="assets/images/Consultame_Footer.png" alt="Logo consúltame"/>
    </a>
    <p class="copyright">&#169; Plexus 2020</p>
    <p class="copyright">{{version}}</p>
  </footer>

</div>

