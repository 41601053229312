import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { InvitationsInterface } from 'src/app/store/interface/invitations.interface';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/urls';
import { Constant } from '../../utils/constant';

@Injectable()
export class InviteParticipantsService {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public sendInvitations(invitations: InvitationsInterface): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.post(
      this._appConfig.getConfig().baseUrl + urls.final.invitations,
      invitations,
      { headers }
    );
  }
}
