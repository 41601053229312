import { Action, createReducer, on } from '@ngrx/store';
import { loginAction } from './login.action';
import { loginInitialState } from './login.state';
import { LoginInterface } from '../interface/login-interface';

export const loginReducer = createReducer(
  loginInitialState,
  on(loginAction.login, (state) => ({
    ...state,
    isLogged: false,
    isLogging: true,
  })),
  on(loginAction.loginSuccess, (state, { user }) => ({
    ...state,
    userName: user,
    isLogged: true,
    isLogging: false,
    error: false,
  })),
  on(loginAction.loginFailed, (state /*, { error }*/) => ({
    ...state,
    userName: null,
    isLogged: false,
    isLogging: false,
    error: true,
  })),
  on(loginAction.removeUser, (state) => ({
    ...state,
    userName: null,
    isLogged: false,
    isLogging: false,
    error: false,
  }))
);

export function loginReducers(
  state: LoginInterface | undefined,
  action: Action
) {
  return loginReducer(state, action);
}
