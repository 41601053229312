import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../store/root.state';
import { Constant } from '../../utils/constant';
import { rootAction } from '../../store/root.action';

@Injectable()
export class GetBrowserService {
  constructor(private _store: Store<RootState>) {}

  public getBrowser(): void {
    if (navigator.userAgent.indexOf(Constant.BROWSER.ED) !== -1) {
      const version = parseInt(
        navigator.userAgent.substr(navigator.userAgent.lastIndexOf('/') + 1),
        0
      );
      if (version <= 18) {
        this.setBrowserName(Constant.BROWSER.OLD_ED);
      } else {
        this.setBrowserName(Constant.BROWSER.ED);
      }
    } else if (
      (navigator.userAgent.indexOf(Constant.BROWSER.OP) ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      this.setBrowserName(Constant.BROWSER.OP);
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.CH) !== -1) {
      this.setBrowserName(Constant.BROWSER.CH);
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.iOSCH) !== -1) {
      this.setBrowserName(Constant.BROWSER.iOSCH);
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.SA) !== -1) {
      this.setBrowserName(Constant.BROWSER.SA);
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.FF) !== -1) {
      this.setBrowserName(Constant.BROWSER.FF);
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.IE) !== -1) {
      this.setBrowserName(Constant.BROWSER.IE);
    } else {
      this.setBrowserName(Constant.BROWSER.UNK);
    }

    if (navigator.userAgent.indexOf(Constant.SO.IOS) !== -1) {
      this.setSO(Constant.SO.IOS_TEXT);
    } else if (navigator.userAgent.indexOf(Constant.SO.ANDROID) !== -1) {
      this.setSO(Constant.SO.ANDROID);
    } else {
      this.setSO(Constant.SO.DESKTOP);
    }
  }

  private setBrowserName(name: string) {
    this._store.dispatch(
      rootAction.setBrowserName({
        browser: name,
      })
    );
  }

  private setSO(name: string) {
    this._store.dispatch(
      rootAction.setSo({
        so: name,
      })
    );
  }

  public _getBrowserVersion(browserName: string): string {
    let version: string;
    switch (browserName) {
      case Constant.BROWSER.CH:
        version = this.getVersion(browserName, ' ');
        break;

      case Constant.BROWSER.iOSCH:
        version = this.getVersion(browserName, ' ');
        break;

      case Constant.BROWSER.IE:
        version = this.getVersion(browserName, ';');
        break;

      case Constant.BROWSER.SA:
        version = this.getVersion('Version', ' ');
        break;

      case Constant.BROWSER.ED:
      case Constant.BROWSER.FF:
      case Constant.BROWSER.OP:
        version = navigator.userAgent.substring(
          navigator.userAgent.lastIndexOf('/') + 1
        );
        break;

      default:
        version = Constant.BROWSER.UNK;
        break;
    }

    return version;
  }

  private getVersion(browserName: string, init: string): string {
    let version: string;
    let sizeBrowserName: number = browserName.length + 1;
    version = navigator.userAgent.substring(
      navigator.userAgent.indexOf(browserName + '/') + sizeBrowserName,
      navigator.userAgent.indexOf(
        init,
        navigator.userAgent.indexOf(browserName + '/')
      )
    );
    return version;
  }

  private checkVersion(version: string, min: string, max: string): boolean {
    const versionCompare = require('version_compare');
    const resultMin = versionCompare.gte(version, min);
    const resultMax = versionCompare.lte(version, max);
    return resultMin && resultMax;
  }

  public _checkMinMaxBrowserVersion(browser: string, version: string) {
    let correctVersion = false;
    switch (browser) {
      case Constant.BROWSER.CH:
        correctVersion = this.checkVersion(version, Constant.VERSIONS.CHROME.MIN, Constant.VERSIONS.CHROME.MAX);
        break;
      case Constant.BROWSER.FF:
        correctVersion = this.checkVersion(version, Constant.VERSIONS.FIREFOX.MIN, Constant.VERSIONS.FIREFOX.MAX);
        break;
      case Constant.BROWSER.ED:
        correctVersion = this.checkVersion(version, Constant.VERSIONS.EDGE.MIN, Constant.VERSIONS.EDGE.MAX);
        break;
      case Constant.BROWSER.SA:
        correctVersion = this.checkVersion(version, Constant.VERSIONS.SAFARI.MIN, Constant.VERSIONS.SAFARI.MAX);
        break;
      case Constant.BROWSER.OP:
        correctVersion = this.checkVersion(version, Constant.VERSIONS.OPERA.MIN, Constant.VERSIONS.OPERA.MAX);
        break;
      default:
        correctVersion = false;
        break;
    }
    return correctVersion;
  }

  public checkBrowserWebRTC(): boolean {
    const webRTC = window.RTCPeerConnection;
    return !!webRTC;
  }
}
