import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TextsInterface } from '../../interfaces/texts-interface';
import { Observable, of } from 'rxjs';
import { Constant } from '../../utils/constant';
import { urls } from '../../utils/urls';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  constructor(
    private readonly _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public getText(group, location, lang, key): Observable<string> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const params = new HttpParams()
      .set('group', group)
      .set('location', location)
      .set('language', lang)
      .set('txtKey', key);
    return of(null);
  }

  public getAllText(group, location, lang): Observable<TextsInterface[]> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const params = new HttpParams()
      .set('group', group)
      .set('location', location)
      .set('language', lang);
    return this._http.get<TextsInterface[]>(
      this._appConfig.getConfig().baseUrl + urls.final.getAllTexts,
      { headers, params }
    );
  }

  public filterText(key: string, texts: TextsInterface[]): string {
    return texts.filter((item) => item.txtKey === key)[0].content;
  }
}
