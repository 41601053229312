import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import localeES from '@angular/common/locales/es';
import localeESExtra from '@angular/common/locales/extra/es';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData, DatePipe } from '@angular/common';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { reducer } from './store/root.reducer';
import { GetBrowserService } from './services/get-browser/get-browser.service';
import { EffectsModule } from '@ngrx/effects';
import { RootEffect } from './store/root.effect';
import { ComponentsModule } from './components/components.module';
import { appointmentReducer } from './store/appointment/appointment.reducer';
import { AppointmentEffect } from './store/appointment/appointment.effect';
import { centerReducers } from './store/centerResponse/center.reducer';
import { CenterEffect } from './store/centerResponse/center.effect';
import { HttpErrorInterceptor } from './services/http-interceptors/http-error.interceptor';
import { PathParamReplaceInterceptor } from './services/http-interceptors/path-param-replace.interceptor';
import { CheckinEffect } from './store/checkin/checkin.effect';
import { checkinReducer } from './store/checkin/checkin.reducer';
import { AppConfigService } from './services/app-config/app-config.service';
import { ModalComponent } from './services/modal/modal/modal.component';
import { ToastComponent } from './services/toast/toast/toast.component';
import { wsFileReducers } from './store/ws-file/ws.reducer';
import { participantReducers } from './store/participant/participant.reducer';
import { configAppReducer } from './store/configApp/config.reducer';
import { ConfigAppEffect } from './store/configApp/config.effect';
import { IsGroupalAppEffect } from './store/goupal/groupal.effect';
import { isGroupalAppReducer } from './store/goupal/groupal.reducer';
import { randomParticipantReducer } from './store/random-participant/random-participant.reducer';
import { TextEffect } from './store/text/text.effect';
import { textReducer } from './store/text/text.reducer';
import { loginReducer } from './store/login/login.reducer';
import { LoginEffect } from './store/login/login.effect';
import { localStorageSync } from 'ngrx-store-localstorage';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};
registerLocaleData(localeES, localeESExtra);

export function translateLoaderFactory(http: any, appConfig: AppConfigService) {
    let ruteTexts: string;
    if (appConfig.getConfig().translate) {
      ruteTexts = appConfig.getConfig().translate;
    } else {
      ruteTexts = './assets/literals/PLEX/'
    }

    return new TranslateHttpLoader(
      http,
      ruteTexts,
      `.json?hashCache=${Date.now()}`
    );
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'login',
      'participant'
    ],
    rehydrate: true,
    storage: window.sessionStorage,
    storageKeySerializer: (key) => `consultame_${key}`
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent, ModalComponent, ToastComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, AppConfigService],
      },
      defaultLanguage: 'es'
    }),
    StoreModule.forRoot({
      config: reducer,
      configApp: configAppReducer,
      appointment: appointmentReducer,
      center: centerReducers,
      checkin: checkinReducer,
      wsfile: wsFileReducers,
      participant: participantReducers,
      groupalApp: isGroupalAppReducer,
      randomParticipant: randomParticipantReducer,
      text: textReducer,
      login: loginReducer,
    }, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      RootEffect,
      AppointmentEffect,
      CenterEffect,
      CheckinEffect,
      ConfigAppEffect,
      IsGroupalAppEffect,
      TextEffect,
      LoginEffect,
    ]),
    ComponentsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    GetBrowserService,
    {
      provide: LOCALE_ID,
      useValue: 'es-ES',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PathParamReplaceInterceptor,
      multi: true,
    },
    DatePipe
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
