import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNoFoundComponent } from './views/page-no-found/page-no-found.component';
import { AppComponent } from './app.component';
import { AdminGuard } from './admin.guard';
import { LoginComponent } from './login/login/login.component';
import { LoginGuard } from './login.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'rooms',
    loadChildren: () => import('./room/room.module').then((m) => m.RoomModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./test-room/test-room.module').then((m) => m.TestRoomModule),
  },
  {
    path: ':idRoom',
    component: AppComponent,
  },
  {
    path: 'assistant',
    loadChildren: () =>
      import('./assistant/assistant.module').then((m) => m.AssistantModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'views',
    loadChildren: () =>
      import('./views/views.module').then((m) => m.ViewsModule),
  },
  // { path: 'error/:code', component: PageNoFoundComponent },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
