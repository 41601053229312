import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOGIN, LoginState } from './login.state';
import { LoginInterface } from '../interface/login-interface';

export const selectLogin = createFeatureSelector<LoginState, LoginInterface>(
  LOGIN
);

export const loggedUser = createSelector(
  selectLogin,
  (state) => state.userName
);

export const pending = createSelector(selectLogin, (state) => state.isLogging);

export const logged = createSelector(selectLogin, (state) => state.isLogged);

export const error = createSelector(selectLogin, (state) => state.error);
