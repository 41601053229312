import { Action, createReducer, on } from '@ngrx/store';
import { initState } from './root.state';
import { rootAction } from './root.action';
import { StateInterface } from './interface/state.interface';

export const rootReducer = createReducer(
  initState,
  on(rootAction.setPermisionCamera, (state, { permission }) => ({
    ...state,
    camera: permission,
  })),

  on(rootAction.setPermisionMicrophone, (state, { permission }) => ({
    ...state,
    microphone: permission,
  })),

  on(rootAction.setErrors, (state, { errors }) => ({
    ...state,
    errorMessages: errors,
  })),

  on(rootAction.setBrowserName, (state, { browser }) => ({
    ...state,
    browser,
  })),

  on(rootAction.setModalStatus, (state, { modalStatus }) => ({
    ...state,
    modalStatus,
  })),

  on(rootAction.setAudioInputDevices, (state, { audioInputDevices }) => ({
    ...state,
    audioInputDevices,
  })),

  on(rootAction.setIsTest, (state, { isTest }) => ({
    ...state,
    isTest,
  })),

  on(rootAction.setAudioOutputDevices, (state, { audioOutputDevices }) => ({
    ...state,
    audioOutputDevices,
  })),

  on(rootAction.setVideoDevices, (state, { videoDevices }) => ({
    ...state,
    videoDevices,
  })),

  on(rootAction.setSo, (state, { so }) => ({
    ...state,
    so,
  })),

  on(rootAction.setStepTutorial, (state, { stepTutorial }) => ({
    ...state,
    stepTutorial,
  })),

  on(rootAction.setDataParams, (state, { dataParams }) => ({
    ...state,
    dataParams,
  })),

  on(rootAction.setGroupRooms, (state, { roomData }) => ({
    ...state,
    groupRooms: roomData,
  })),

  on(rootAction.setIdRoom, (state, { idRoom }) => ({
    ...state,
    idRoom,
  })),

  on(rootAction.setShowError, (state, { showError }) => ({
    ...state,
    showError,
  })),

  on(rootAction.showToast, (state, { toast }) => ({
    ...state,
    toast,
  })),

  on(rootAction.setDetailOfRoom, (state, { detailOfRoom }) => ({
    ...state,
    detailOfRoom,
  })),

  on(rootAction.setStatusRecording, (state, { statusRecording }) => ({
    ...state,
    statusRecording,
  })),
);

export function reducer(state: StateInterface | undefined, action: Action) {
  return rootReducer(state, action);
}
