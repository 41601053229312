import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../utils/constant';
import { urls } from '../../utils/urls';
import { AppConfigService } from '../app-config/app-config.service';
import { IsGroupalInterface } from '../../interfaces/is-groupal.interface';
import { DataParamsInterface } from '../../store/interface/data-params.interface';

@Injectable({
  providedIn: 'root',
})
export class IsGroupalService {
  constructor(
    private readonly _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public getIsGroupal$(room: string): Observable<IsGroupalInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<IsGroupalInterface>(
      this._appConfig.getConfig().baseUrl + urls.final.initCall + room,
      { headers }
    );
  }

  public getGroupalRooms$(room: string): Observable<DataParamsInterface[]> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<DataParamsInterface[]>(
      this._appConfig.getConfig().baseUrl + urls.final.groupalRooms + room,
      { headers }
    );
  }
}
