import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Login] login',
  props<{ username: string; password: string }>()
);

export const loginSuccess = createAction(
  '[Login] login success',
  props<{ user: string }>()
);

export const loginFailed = createAction(
  '[Login] login failed'
  // props<{ error: string }>()
);

export const logout = createAction('[Login] logout');

export const removeUser = createAction('[Login] remove logged user');

export const loginAction = {
  login,
  loginSuccess,
  loginFailed,
  logout,
  removeUser
};
