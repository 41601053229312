import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  urlsNotValid: string[] = [];

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private _router: Router
  ) {
    this.urlsNotValid = [
      '/api/meeting/attachments/participantType/',
      '/api/recording/start/room/',
      '/api/recording/stop/room/'
    ]
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.isUrlValid(request.url)) {
      return next.handle(request).pipe(
        catchError((error) => {
          if (
            error instanceof
            HttpErrorResponse /*&&
            !this._router.url.includes('login')*/
          ) {
            const errorClone = JSON.parse(JSON.stringify(error));
            if (!errorClone.error.status) {
              errorClone.error = {
                status: errorClone.status,
              };
            }
            errorClone.error.status =
              error.status === 404 || error.status === 500 ? 600 : error.status;
            this.errorHandlerService.manageApiError(errorClone.error);
          }

          return throwError(error);
        })
      );
    }
    return next.handle(request);
  }

  isUrlValid(url: string) {
    for (let i = 0; i < this.urlsNotValid.length; i++) {
      const urlNotValid = this.urlsNotValid[i];
      if (url.indexOf(urlNotValid) > -1) {
        return false;
      }
    }
    return true;

  }
}
