import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urls } from 'src/app/utils/urls';
import { Observable, Subject } from 'rxjs';
import { AppConfigService } from '../app-config/app-config.service';
import { SpinnerService } from '../spinner/spinner.service';
import { Room } from 'src/app/interfaces/room.interface';
import { Constant } from '../../utils/constant';
import moment from 'moment';
import { TotalRoomsInterface } from '../../interfaces/total-rooms.interface';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  private roomCreated$: Subject<boolean> = new Subject();

  constructor(
    private httpService: HttpClient,
    private appConfig: AppConfigService,
    private spinnerService: SpinnerService
  ) {}

  private _getEmptyRoom(owner?: string) {

    return {
      group: 'SCS',
      location: 'VIDEOPRO',
      data: (Math.random() * 10000).toFixed(0),
      checkin: false,
      roomType: Constant.JITSI,
      client: '',
      admin: '',
      apiUrl:
        'http://tomcatdev.plexus.local:8080/SCSQMvideoconsultaPro/rest/api',
      provider: Constant.PROVIDER_INTERNAL,
      name: '',
      invitations: [],
      owner: '',
      template: {
        backgroundColor: '',
        logo: '',
      },
      printScreenRoles: [],
      inviteRoles: ['ADMIN'],
      shareFileRoles: ['ADMIN', 'USER', 'GUEST'],
      detail: {
        callDuration: 150,
        callDurationUnit: 'MIN',
        fromDate: moment(new Date().getTime()).format(
          Constant.FORMAT_DATE_ROOMS_GENERATOR
        ),
        toDate: '',
        maxParticipants: 5,
        currentParticipants: 0,
        userProblem: false,
        userReady: false,
        printScreenRoles: [],
        inviteRoles: ['ADMIN'],
        shareFileRoles: ['ADMIN', 'USER', 'GUEST'],
      },
    };
  }

  public createRoom(params: any, owner?: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const roomData = this._getEmptyRoom(owner);

    if (!params.immediateRoom) {
      roomData.detail.fromDate = moment(
        new Date(
          `${params.creationDate} ${params.creationHour}:${params.creationMinute}`
        ).getTime()
      ).format(Constant.FORMAT_DATE_ROOMS_GENERATOR);
    }

    if (params.creationRoomType !== Constant.JITSI) {
      roomData.roomType = params.creationRoomType;
    }
    roomData.name = params.name;
    roomData.invitations = params.invitations;
    roomData.detail.maxParticipants = params.invitations.length + 2;
    roomData.owner = params.owner;

    this.spinnerService.show();
    return this.httpService.post(
      this.appConfig.getConfig().baseUrl + urls.final.roomsCreate,
      roomData,
      { headers }
    );
  }

  public getRoomList(numberItem?: number, page?: number, owner?: string): Observable<Room[]> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    let params = '';
    if (numberItem && page !== null) {
      params = `?page=${page}&size=${numberItem}&order=DSC`;

      if (owner) {
        params += `&owner=${owner}`;
      }

      this.spinnerService.show();
      return this.httpService.get<Room[]>(
        this.appConfig.getConfig().baseUrl + urls.final.rooms + params,
        { headers }
      );
    } else {
      if (owner) {
        params += `?owner=${owner}`;
      }
      this.spinnerService.show();
      return this.httpService.get<Room[]>(
        this.appConfig.getConfig().baseUrl + urls.final.rooms + params,
        { headers }
      );
    }
  }

  public getAllRooms(owner?: string): Observable<TotalRoomsInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    let params = '';
    if (owner) {
      params = `?owner=${owner}`;
    }
    this.spinnerService.show();
    return this.httpService.get<TotalRoomsInterface>(
      this.appConfig.getConfig().baseUrl + urls.final.totalRooms + params,
      { headers }
    );
  }

  /* TODO añadir boton que llame a este metodo */
  public kickUser(room: string, user: string): Observable<boolean> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const url = urls.final.kickUser
      .replace('{room}', room);
    return this.httpService.post<boolean>(this.appConfig.getConfig().baseUrl + url + `?email=${user}`, null, { headers });
  }

  public emitRoomCreated(isCreated: boolean) {
    this.roomCreated$.next(isCreated);
  }

  public onRoomCreated$(): Observable<boolean> {
    return this.roomCreated$.asObservable();
  }
}
