import { LoginInterface } from '../interface/login-interface';

export const LOGIN = 'login';

export interface LoginState {
  [LOGIN]: LoginInterface;
}

export const loginInitialState: LoginInterface = {
  userName: null,
  isLogging: false,
  isLogged: false,
  error: false,
};
