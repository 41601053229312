import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../utils/urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppointmentDateInterface } from '../../interfaces/appointment-date.interface';
import { Constant } from 'src/app/utils/constant';
import { AppConfigService } from '../app-config/app-config.service';
import { ConfigAppInterface } from '../../store/interface/config-app.interface';
import { InternalConfigurationInterface } from '../../interfaces/internal-configuration.interface';
import { GuestInterface } from '../../interfaces/guest.interface';

@Injectable({
  providedIn: 'root',
})
export class GetDataService {
  constructor(
    private readonly _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public getAppointmentData$(
    room: string
  ): Observable<AppointmentDateInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<AppointmentDateInterface>(
      this._appConfig.getConfig().baseUrl + urls.final.appointmentData + room,
      { params: { lang: localStorage.getItem('lang') }, headers }
    );
  }

  public putState$(state: string): Observable<void> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const body = {
      action: state,
    };
    return this._http.put<void>(
      this._appConfig.getConfig().baseUrl + urls.final.appointmentDataMng,
      body,
      { headers }
    );
  }

  public deleteState$(): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: Constant.BASIC_AUTH_TOKEN,
      }),
      body: {
        action: 'error',
      },
      params: { lang: localStorage.getItem('lang') },
    };
    return this._http.delete<void>(
      this._appConfig.getConfig().baseUrl + urls.final.appointmentDataMng,
      options
    );
  }

  public getConfigurationApp$(): Observable<ConfigAppInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<ConfigAppInterface>(
      this._appConfig.getConfig().baseUrl + urls.final.configurationApp,
      { headers }
    );
  }

  public getConfigurationRoomTest$(
    group: string,
    location: string
  ): Observable<ConfigAppInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const urlFinal = urls.final.configurationApp
      .replace('{groupID}', group)
      .replace('{locationID}', location);
    return this._http.get<ConfigAppInterface>(
      this._appConfig.getConfig().baseUrl + urlFinal,
      {
        headers,
      }
    );
  }

  public getConfigurationInternal$(
    room: string
  ): Observable<InternalConfigurationInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<InternalConfigurationInterface>(
      this._appConfig.getConfig().baseUrl +
        urls.final.configurationInternalApp +
        room,
      { headers }
    );
  }

  public patchUserJoined(idRoom: string): void {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    this._http
      .patch(
        this._appConfig.getConfig().baseUrl + urls.final.accessControl + idRoom,
        null,
        { headers }
      )
      .subscribe((res) => console.warn(res));
  }

  public deleteUserLeft(idRoom: string): void {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    this._http
      .delete(
        this._appConfig.getConfig().baseUrl + urls.final.accessControl + idRoom,
        {
          headers,
        }
      )
      .subscribe((res) => {
        console.warn(res);
      });
  }

  public getEmailGuest(guest: string): Observable<GuestInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get<GuestInterface>(
      this._appConfig.getConfig().baseUrl + urls.final.getEmailGuest + guest,
      { headers }
    );
  }
}
