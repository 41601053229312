import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Constant } from 'src/app/utils/constant';
import { PermissionInterface } from '../../store/interface/permission.interface';
import {
  selectAudioInputDevices,
  selectCamera,
  selectMicrophone,
  selectAudioOutputDevices,
  selectVideoDevices,
  selectBrowser,
  selectStepTutorial,
  selectRoomType,
  selectIsTest,
} from '../../store/root.selector';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { JistiApiService } from '../service/jisti-api/jisti-api.service';
import { rootAction } from '../../store/root.action';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '../../services/app-config/app-config.service';
import { TextService } from '../../services/text/text.service';
import { selectAllText } from '../../store/text/text.selector';
import { TextsInterface } from '../../interfaces/texts-interface';

@Component({
  selector: 'app-devices-selector',
  templateUrl: './devices-selector.component.html',
  styleUrls: ['./devices-selector.component.scss'],
})
export class DevicesSelectorComponent implements OnInit, OnDestroy {
  @Input() idRoom?: string;
  @Input() isTestRoom: boolean;
  @ViewChild('videoTutorial') videoRef: ElementRef;
  public isTest: boolean;
  private text: TextsInterface[];
  get video(): HTMLVideoElement {
    return this.videoRef.nativeElement;
  }

  private _isCallTeams: boolean;

  public tutorialStep: number;

  public selectedSpeakers;
  public selectedMicrophone;
  public selectedCamera;

  public speakersDevices: MediaDeviceInfo[];
  public cameraDevices: MediaDeviceInfo[];
  public microphoneDevices: MediaDeviceInfo[];

  public speakers: string;
  public microphone: string;
  public camera: string;

  public permisionMicro: PermissionInterface;
  public permisionCamera: PermissionInterface;

  public isFirefox: boolean;
  public isSafari: boolean;

  private _unsubscriber$: Subject<void> = new Subject();
  private audio: HTMLAudioElement | any;
  public isAudioEnded: boolean;
  public isVoiceTesting: boolean;
  private audioContext: AudioContext;
  private analyser: AnalyserNode;
  private microphoneSrc: MediaStreamAudioSourceNode;
  private javascriptNode: ScriptProcessorNode;
  private inputAudioDeviceId: string;

  public problemsClicked: number;

  public domain: string;

  constructor(
    private _store: Store,
    public _jistiService: JistiApiService,
    private _router: Router,
    private _appConfig: AppConfigService,
    private _activatedRoute: ActivatedRoute,
    private _textService: TextService
  ) {
    this.domain = this._appConfig.getConfig().jitsiDomain;
    this.isAudioEnded = true;
    this.isVoiceTesting = false;
    this.speakersDevices = [];
    this.cameraDevices = [];
    this.microphoneDevices = [];
    this.audio = new Audio();
    this.audio.src = '/assets/audio/speakersTest.mp3';
    this.audio.load();
    this.audio.onended = () => {
      this.isAudioEnded = true;
    };
  }

  ngOnInit(): void {
    if (this.isTestRoom) {
      this._getTextInfo();
    }
    this._isTeams();
    this.getPermisionMicro();
    this.getPermisionCamera();

    this.getAudioInputDevices();
    this.getVideoOutputDevices();
    this.getAudioOutputDevices();

    this.speakers = Constant.SPEAKERS;
    this.microphone = Constant.MICROPHONE;
    this.camera = Constant.CAMERA;

    this.microphoneDevices = [];
    this.cameraDevices = [];

    this.isFirefox = false;
    this._checkBrowser();
    this._getStepTutorial();
    this._isTest();
  }

  private _isTest(): void {
    this._store
      .select(selectIsTest)
      .pipe(
        takeUntil(this._unsubscriber$),
        filter((res) => res === true)
      )
      .subscribe((res) => {
        this.isTest = res;
      });
  }

  private _isTeams(): void {
    this._store
      .select(selectRoomType)
      .pipe(
        takeUntil(this._unsubscriber$),
        filter((res) => res !== '')
      )
      .subscribe((res) => (this._isCallTeams = res === Constant.TEAMS));
  }

  private _getStepTutorial() {
    this._store
      .select(selectStepTutorial)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((data) => {
        this.tutorialStep = data;
        switch (this.tutorialStep) {
          case 2:
            this.testMicrophone();
            break;
          case 3:
            this.isVoiceTesting = false;
            this.testMicrophone();
            navigator.mediaDevices
              .getUserMedia({
                video: true,
              })
              .then((dataVideo) => {
                console.log('*** dataVideo', dataVideo);
                this.video.srcObject = dataVideo;
              });
            // setTimeout( () => {
            //   document.getElementById('testVideo').appendChild(document.getElementsByTagName('iframe')[0])
            // },2000);

            break;
        }
      });
  }

  private _checkBrowser() {
    this._store
      .select(selectBrowser)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((browser: string) => {
        this.isFirefox = browser === Constant.BROWSER.FF;
        this.isSafari = browser === Constant.BROWSER.SA;
      });
  }

  public changeSpeakers(input) {
    this.selectedSpeakers = input;
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const deviceItem = devices.filter(
          (device) => device.kind === 'audiooutput'
        );
        const deviceOutputAudio = this.speakersDevices.filter(
          (speak) => speak.kind === 'audiooutput'
        );
        deviceOutputAudio.find((audioOutput, index) => {
          if (audioOutput.deviceId === input) {
            this.audio.setSinkId(deviceItem[index].deviceId);
          }
        });
      })
      .catch((err) => console.log(err));

    const deviceSelected = this.speakersDevices.find(
      (d: Device) => d.deviceId === this.selectedSpeakers
    );
    if (!this.isTest) {
      this._jistiService.setAudioOutputDevice(
        deviceSelected.label,
        deviceSelected.deviceId
      );
    }
  }

  public changeMicrophone(input) {
    this.selectedMicrophone = input;
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const deviceItem = devices.filter(
          (device) => device.kind === 'audioinput'
        );
        const deviceInputAudio = this.microphoneDevices.filter(
          (speak) => speak.kind === 'audioinput'
        );
        deviceInputAudio.find((audioinput, index) => {
          if (audioinput.deviceId === input) {
            this.inputAudioDeviceId = deviceItem[index].deviceId;
          }
        });
        if (this.isVoiceTesting) {
          this.testMicrophone();
        }
      })
      .catch((err) => console.log(err));

    const deviceSelected = this.microphoneDevices.find(
      (d: Device) => d.deviceId === this.selectedMicrophone
    );
    if (!this.isTest) {
      this._jistiService.setAudioInputDevice(
        deviceSelected.label,
        deviceSelected.deviceId
      );
    }
  }

  public changeCamera(input) {
    this.selectedCamera = input;

    const deviceSelected = this.cameraDevices.find(
      (d: Device) => d.deviceId === this.selectedCamera
    );
    if (!this.isTest) {
      this._jistiService.setVideoInputDevice(
        deviceSelected.label,
        deviceSelected.deviceId
      );
    }
  }

  public getPermisionCamera(): void {
    this._store
      .select(selectCamera)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((permision) => {
        this.permisionCamera = permision;
      });
  }

  public getPermisionMicro(): void {
    this._store
      .select(selectMicrophone)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((permision) => {
        this.permisionMicro = permision;
      });
  }

  public getAudioInputDevices(): void {
    this._store
      .select(selectAudioInputDevices)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((devices: any) => {
        if (
          !this.selectedMicrophone &&
          devices &&
          devices.length > 0 &&
          devices.find((d: Device) => d.selected)
        ) {
          this.selectedMicrophone = devices.find(
            (d: Device) => d.selected
          ).deviceId;
        }
        this.microphoneDevices = devices;
      });
  }

  public getAudioOutputDevices(): void {
    this._store
      .select(selectAudioOutputDevices)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((devices: any) => {
        if (
          !this.selectedSpeakers &&
          devices &&
          devices.length > 0 &&
          devices.find((d: Device) => d.selected)
        ) {
          this.selectedSpeakers = devices.find(
            (d: Device) => d.selected
          ).deviceId;
        }
        this.speakersDevices = devices;
      });
  }

  public getVideoOutputDevices(): void {
    this._store
      .select(selectVideoDevices)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((devices: any) => {
        if (
          !this.selectedCamera &&
          devices &&
          devices.length > 0 &&
          devices.find((d: Device) => d.selected)
        ) {
          this.selectedCamera = devices.find(
            (d: Device) => d.selected
          ).deviceId;
        }
        this.cameraDevices = devices;
      });
  }

  public playAudioSample() {
    this.audio.play();
    this.isAudioEnded = false;
  }

  public testMicrophone() {
    if (this.isVoiceTesting) {
      this.javascriptNode.disconnect();
      this.microphoneSrc.disconnect();
      this.analyser.disconnect();
      this.audioContext.close();
      this.isVoiceTesting = false;
    } else if (!this._isCallTeams) {
      this._drawBarSound();
    } else {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log(devices);
        const deviceInputDefault = devices.filter((device) => {
          return device.kind === 'audioinput' && device.deviceId === 'default';
        })[0];
        this._drawBarSound(deviceInputDefault.deviceId);
      });
    }
  }

  private _drawBarSound(deviceGroupId: string = null): void {
    this.isVoiceTesting = true;
    let constraint = {};
    if (deviceGroupId) {
      constraint = {
        audio: {
          deviceId: deviceGroupId,
        },
        video: false,
      };
    } else {
      constraint = {
        audio: {
          deviceId: this.inputAudioDeviceId
            ? { exact: this.inputAudioDeviceId }
            : undefined,
        },
        video: false,
      };
    }
    navigator.mediaDevices
      .getUserMedia(constraint)
      .then((stream) => {
        const bars = []; // We'll use this later
        const audioContext = new AudioContext();
        let drawing = false;
        const canvas = document.querySelector('canvas');
        const canvasContext = canvas.getContext('2d');
        const width = canvas.offsetWidth;
        const height = canvas.offsetHeight;
        const halfHeight = height / 2;
        const barWidth = 4;
        const barGutter = 2;
        const barColor = '#1393fe';
        const cornerRadius = 20;

        // Set the size of the canvas context to the size of the canvas element
        canvasContext.canvas.width = width;
        canvasContext.canvas.height = height;
        const input = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        const scriptProcessor = audioContext.createScriptProcessor();
        // Some analyser setup
        analyser.smoothingTimeConstant = 0.3;
        analyser.fftSize = 1024;
        input.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        const getAverageVolume = (array) => {
          const length = array.length;
          let values = 0;
          let i = 0;

          for (; i < length; i++) {
            values += array[i];
          }

          return values / length;
        };
        const renderBars = (bars) => {
          if (!drawing) {
            drawing = true;

            window.requestAnimationFrame(() => {
              canvasContext.clearRect(0, 0, width, height);

              bars.forEach((bar, index) => {
                canvasContext.fillStyle = barColor;
                canvasContext.lineJoin = 'round';
                canvasContext.lineWidth = cornerRadius;
                // Top part of the bar
                canvasContext.fillRect(
                  index * (barWidth + barGutter),
                  halfHeight - halfHeight * (bar / 100),
                  barWidth,
                  bar === 0 ? 1 : halfHeight * (bar / 100)
                );

                // Bottom part of the bar
                canvasContext.fillRect(
                  index * (barWidth + barGutter),
                  halfHeight,
                  barWidth,
                  bar === 0 ? 1 : halfHeight * (bar / 100)
                );
              });

              drawing = false;
            });
          }
        };
        const processInput = (audioProcessingEvent) => {
          const tempArray = new Uint8Array(analyser.frequencyBinCount);

          analyser.getByteFrequencyData(tempArray);
          bars.push(getAverageVolume(tempArray));

          // We'll create this later

          renderBars(
            bars.slice(
              bars.length - Math.floor(width / (barWidth + barGutter))
            ),
            // @ts-ignore
            bars.length
          );
        };
        scriptProcessor.onaudioprocess = processInput;
      })
      .catch((err) => {
        this.isVoiceTesting = false;
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  public nextStepTutorial() {
    this._store.dispatch(
      rootAction.setStepTutorial({ stepTutorial: this.tutorialStep + 1 })
    );
    if (this.tutorialStep === 4) {
      if (!this.isTestRoom) {
        this._router.navigate(['assistant', 'waiting', this.idRoom, 'waiting']);
      } else {
      }
    }
  }

  public showProblems(stepProblem: number) {
    this.problemsClicked = stepProblem;
    this._store.dispatch(
      rootAction.setShowError({
        showError: true,
      })
    );
  }

  public getText(key): string {
    if (key && this.text?.length) {
      return this._textService.filterText(key, this.text);
    }
  }

  private _getTextInfo() {
    this._store
      .select(selectAllText)
      .pipe(
        takeUntil(this._unsubscriber$),
        filter((res) => res !== null)
      )
      .subscribe((res) => (this.text = res));
  }
}
