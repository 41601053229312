import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from '../../../utils/constant';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { urls } from '../../../utils/urls';

@Injectable({
  providedIn: 'root',
})

export class JibriService {

  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  public getStatus(idRoom: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.get(
      this._appConfig.getConfig().baseUrl + urls.final.recordingState + idRoom,
      { headers }
    );
  }

  public startRecording(idRoom: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    return this._http.post(
      this._appConfig.getConfig().baseUrl + urls.final.recordingStart + idRoom,
      {idRoom: idRoom},
      { headers }
    );
  }

  public stopRecording(idRoom: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const url = urls.final.recordingStop
      .replace('{room}', idRoom)
      .replace('{jibri}', 'false');
    return this._http.post(
      this._appConfig.getConfig().baseUrl + url,
      {idRoom: idRoom},
      { headers }
    );
  }

}
