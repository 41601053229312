import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { GetDataService } from 'src/app/services/get-data/get-data.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { Constant } from '../../utils/constant';
import { selectIdRoom } from '../root.selector';
import { checkinActions } from './checkin.action';

@Injectable()
export class CheckinEffect {
  constructor(
    private actions$: Actions,
    private _getDataService: GetDataService,
    private _spinnerService: SpinnerService,
    private _router: Router,
    private _store: Store
  ) {}

  putDoCheckin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkinActions.putDoCheckin),
      mergeMap(() => {
        this._spinnerService.show();
        return this._getDataService.putState$(Constant.CHECKIN).pipe(
          map((data) => {
            this._spinnerService.hide();
            return checkinActions.setIsCheckinCompleted({
              isCheckinCompleted: true,
            });
          }),
          catchError((error) => {
            this._spinnerService.hide();
            return of(
              checkinActions.setIsDisabledCheckinButton({
                isDisabledCheckinButton: false,
              })
            );
          })
        );
      })
    );
  });

  putDoFinish$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkinActions.putDoFinish),
      mergeMap(() => {
        this._spinnerService.show();
        return this._getDataService.putState$(Constant.FINISH).pipe(
          map((data) => {
            this._spinnerService.hide();
            return checkinActions.setIsCheckinCompleted({
              isCheckinCompleted: true,
            });
          }),
          catchError((error) => {
            this._spinnerService.hide();
            return of(
              checkinActions.setIsDisabledCheckinButton({
                isDisabledCheckinButton: false,
              })
            );
          })
        );
      })
    );
  });

  putDoProblems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkinActions.putDoProblems),
      mergeMap(() => {
        this._spinnerService.show();
        return this._getDataService.deleteState$().pipe(
          map((data) => {
            this._spinnerService.hide();
            return checkinActions.setIsCheckinProblems({
              isCheckinProblems: true,
            });
          }),
          catchError((error) => {
            this._spinnerService.hide();
            return of(
              checkinActions.setIsDisabledCheckinButton({
                isDisabledCheckinButton: false,
              })
            );
          })
        );
      })
    );
  });

  checkinCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkinActions.setIsCheckinCompleted),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this._store
                .select(selectIdRoom)
                .pipe(
                  filter((idRoom: string) => idRoom !== null && idRoom !== '')
                )
            )
          )
        ),
        map(([action, idRoom]) => {
          this._router.navigate(['assistant', 'waiting', idRoom, 'waiting']);
        })
      ),
    { dispatch: false }
  );
}
