import { createAction, props } from '@ngrx/store';

const putDoCheckin = createAction('[jisti] putDoCheckin');
const putDoFinish = createAction('[jisti] putDoFinish');
const putDoProblems = createAction('[jisti] putDoProblems');

const setIsCheckinCompleted = createAction(
  '[jisti] setIsCheckinCompleted',
  props<{ isCheckinCompleted: boolean }>()
);

const setIsCheckinProblems = createAction(
  '[jisti] setIsCheckinProblems',
  props<{ isCheckinProblems: boolean }>()
);

const setIsDoctorOnCall = createAction(
  '[jisti] setIsDoctorOnCall',
  props<{ isDoctorOnCall: boolean }>()
);

const setIsDisabledCheckinButton = createAction(
  '[jisti] setIsDisabledCheckinButton',
  props<{ isDisabledCheckinButton: boolean }>()
);

export const checkinActions = {
  putDoCheckin,
  putDoFinish,
  putDoProblems,
  setIsCheckinCompleted,
  setIsCheckinProblems,
  setIsDoctorOnCall,
  setIsDisabledCheckinButton,
};
