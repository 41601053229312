import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalService } from '../modal/modal.service';
import { urls } from '../../utils/urls';
import { AppConfigService } from '../app-config/app-config.service';
import { GetAllFilesInterface } from '../../interfaces/get-all-files.interface';
import * as SockJS from '../../utils/libraries/sock.js';
import * as LibraryStomp from '../../utils/libraries/stomp.js';
import { Store } from '@ngrx/store';
import { wsFileAction } from '../../store/ws-file/ws.action';
import { TypeUser } from '../../types/type-user';
import { Constant } from '../../utils/constant';

@Injectable({
  providedIn: 'root',
})
export class HandlerFilesService {
  private _stompClient: any;
  private _urlSubscribeWS: string;
  private _numbersFilesShared: number;
  private _inProgressShared: number;
  constructor(
    private _http: HttpClient,
    private _modal: ModalService,
    private _appConfig: AppConfigService,
    private _store: Store
  ) {
    this._numbersFilesShared = 0;
    this._inProgressShared = 0;
  }

  public connectWS(roomId: string, code: string): void {
    const socket = new SockJS(
      `${this._appConfig.getConfig().baseUrl}${urls.final.wsUrl}`
    );
    this._urlSubscribeWS = `${urls.final.wsUrlSub}${roomId}`;
    this._stompClient = LibraryStomp.Stomp.over(socket);
    this._stompClient.connect(
      {},
      () => {
        console.warn('WS is Connected');
        this._stompClient.subscribe(this._urlSubscribeWS, (event) => {
          if (event?.body?.search(Constant.UPLOAD_IN_PROGRESS) !== -1) {
            if (event?.body?.search(code) === -1) {
              this._store.dispatch(
                wsFileAction.setProgressWsFile({
                  inProgress: ++this._inProgressShared,
                })
              );
            }
          } else if (event?.body?.search(Constant.UPLOAD_COMPLETE) !== -1) {
            this._store.dispatch(
              wsFileAction.setReceiverWsFile({
                receiver: ++this._numbersFilesShared,
              })
            );
          } else if (event?.body?.search(Constant.DELETED_FILE) !== -1) {
            this._store.dispatch(
              wsFileAction.setReceiverWsFile({
                receiver: --this._numbersFilesShared,
              })
            );
          }
        });
      },
      (err) => {
        console.error(err);
        this._store.dispatch(
          wsFileAction.setReceiverWsFile({ receiver: false })
        );
      }
    );
  }

  public sendMessage(message: string, roomId: string): void {
    if (this._stompClient) {
      this._stompClient.send(`/app/attachments/roomId/${roomId}`, {}, message);
    }
  }

  public sendFiles(
    idRoom: string,
    file: FormData,
    typeUser: TypeUser
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    file.append('roomId', idRoom);
    const urlFinal = urls.final.uploadFiles.replace('{{typeUser}}', typeUser);
    return this._http.post(
      `${this._appConfig.getConfig().baseUrl}${urlFinal}${idRoom}`,
      file,
      {
        reportProgress: true,
        observe: 'events',
        headers,
      }
    );
  }

  public getAllFilesFromRoom(
    idRoom: string,
    typeUser: TypeUser
  ): Observable<GetAllFilesInterface[]> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const urlFinal = urls.final.getAllFiles.replace('{typeUser}', typeUser);
    return this._http.get<GetAllFilesInterface[]>(
      this._appConfig.getConfig().baseUrl + urlFinal + idRoom,
      { headers }
    );
  }

  public downloadAFile(
    idRoom: string,
    nameFile: string,
    userType: TypeUser
  ): Observable<GetAllFilesInterface> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const urlDownload = urls.final.downloadFile
      .replace('{userType}', userType)
      .replace('{room}', idRoom)
      .replace('{filename}', nameFile);
    return this._http.get<GetAllFilesInterface>(
      this._appConfig.getConfig().baseUrl + urlDownload,
      { headers }
    );
  }

  public removeFileFromRoom(
    typeUser: TypeUser,
    idRoom: string,
    name: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: Constant.BASIC_AUTH_TOKEN,
    });
    const urlRemove = urls.final.removeFile
      .replace('{userType}', typeUser)
      .replace('{room}', idRoom)
      .replace('{filename}', name);
    return this._http.delete(this._appConfig.getConfig().baseUrl + urlRemove, {
      headers,
    });
  }
}
