import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { ConvertUnits } from '../../helpers/convert-units';
import { Constant } from '../../utils/constant';
import { ToastService } from '../../services/toast/toast.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { HandlerFilesService } from '../../services/handler-files/handler-files.service';
import { TypeUser } from '../../types/type-user';
import { HttpProgressEvent } from '@angular/common/http';
import { GetAllFilesInterface } from '../../interfaces/get-all-files.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TransformFilesService } from '../../services/transform-files/transform-files.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-files',
  templateUrl: './share-files.component.html',
  styleUrls: ['./share-files.component.scss'],
})
export class ShareFilesComponent implements OnInit, OnDestroy {
  public showDragAndDrop: boolean;
  public files: FormData;
  public filesList: File[];
  private _unsubscribe: Subject<void>;
  @ViewChild('onDropStyle') onDragStyle: ElementRef;
  @Input() view: ViewContainerRef;
  @Input() idGuest: string;
  @Input() idRoom: string;
  @Input() typeUser: TypeUser;
  @Input() isGroup: boolean;
  @Input() roomMgt: string;
  @Input() listFilesShared: GetAllFilesInterface[];
  private _progressUpload: string;
  public idUserFile: string;

  constructor(
    private _toastService: ToastService,
    private _spinnerService: SpinnerService,
    private _handlerFileService: HandlerFilesService,
    private _rendered: Renderer2,
    private _transformFile: TransformFilesService,
    private _modalService: ModalService,
    private _translateService: TranslateService
  ) {
    this.files = new FormData();
    this.filesList = [];
    this.showDragAndDrop = false;
    this._unsubscribe = new Subject<void>();
  }

  ngOnInit(): void {
    this._setIdUserFile();
  }

  public dragIn($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this._addClass();
  }

  dropHandler($event: DragEvent) {
    $event.preventDefault();
    this._removeClass();
    this._sendMessage(
      Constant.UPLOAD_IN_PROGRESS,
      this.isGroup ? this.roomMgt : this.idRoom
    );
    if ($event.dataTransfer.items) {
      for (let i = 0; i < $event.dataTransfer.items.length; i++) {
        if ($event.dataTransfer.items[i].kind === 'file') {
          const file: File = $event.dataTransfer.items[i].getAsFile();
          this.handleFiles(file);
        }
      }
    } else {
      for (let i = 0; i < $event.dataTransfer.files.length; i++) {
        console.log($event.dataTransfer.files[i].name);
      }
    }
  }

  public handleFiles(file: File) {
    if (this.filesList.length >= 10) {
      this._toastService.show('filesLimitMsg', 'warning', false, this.view);
    } else if (this._checkFiles(file)) {
      this.filesList.push(file);
    }
  }

  private _checkFiles(file: File): boolean {
    if (file.type && file.type.indexOf('application/x-msdownload') === -1) {
      const sizeFile = ConvertUnits.convertByteToMb(file.size);
      if (sizeFile > 4) {
        this._toastService.show(Constant.FILE_MAX, 'warning', false, this.view);
        return false;
      } else {
        return true;
      }
    } else {
      this._toastService.show(
        Constant.FILE_EXTENSION_FAIL,
        'warning',
        false,
        this.view
      );
      return false;
    }
  }

  private _sendMessage(message: string, idRoom: string): void {
    this._handlerFileService.sendMessage(
      localStorage.getItem('user-key') + ' ' + message,
      idRoom
    );
  }

  public uploadFiles(): void {
    this._spinnerService.show();
    let cont = 0;
    for (const file of this.filesList) {
      if (cont === 0) {
        this.files.set('file', file, file.name);
      } else {
        this.files.append('file', file, file.name);
      }
      cont++;
    }
    this._handlerFileService
      .sendFiles(this.idGuest ?? this.idRoom, this.files, this.typeUser)
      .subscribe(
        data => {
          if (data.type === 1) {
            this._progressUpload =
              ((data.loaded / data.total) * 100).toFixed(0) + '%';
            this._toastService.show(
              this._progressUpload,
              'info',
              false,
              this.view
            );
          }

          if (data.type === 3) {
            this._spinnerService.hide();
            this.showDragAndDrop = false;
            this._sendMessage(
              Constant.UPLOAD_COMPLETE,
              this.isGroup ? this.roomMgt : this.idRoom
            );
            this._modalService.hide();
          }
        },
        error => {
          this._toastService.show(
            'errorFiles',
            'danger',
            false,
            this.view
          );
        }
      );
  }

  private _removeClass(): void {
    this._rendered.removeClass(this.onDragStyle.nativeElement, 'on-drag');
  }

  private _addClass(): void {
    this._rendered.addClass(this.onDragStyle.nativeElement, 'on-drag');
  }

  public downloadFile(file: GetAllFilesInterface): void {
    this._handlerFileService
      .downloadAFile(file.id, file.name, file.participantType)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((res) => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          this._transformFile.transformFilesStringToUrlDownload(res.file)
        );
        element.setAttribute('download', file.name);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this._modalService.hide();
      });
  }

  public downloadAll(): void {
    if (this.listFilesShared?.length > 0) {
      for (const file of this.listFilesShared) {
        if (file.participantType !== this.typeUser) {
          this.downloadFile(file);
        }
      }
    }
  }

  public deletedFileShare(file: GetAllFilesInterface): void {
    this._handlerFileService
      .removeFileFromRoom(file.participantType, file.id, file.name)
      .subscribe((res) => {
        this._sendMessage(
          Constant.DELETED_FILE,
          this.isGroup ? this.roomMgt : this.idRoom
        );
        this._modalService.hide();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.unsubscribe();
  }

  private _setIdUserFile(): void {
    if (this.idGuest) {
      this.idUserFile = this.idGuest;
    } else if (this.typeUser === Constant.TYPE_USER.ATTENDANT) {
      this.idUserFile = this.idRoom;
    } else {
      this.idUserFile = this.roomMgt;
    }
  }

  public sendNotification(): void {
    this._sendMessage(
      Constant.UPLOAD_IN_PROGRESS,
      this.isGroup ? this.roomMgt : this.idRoom
    );
  }
}
