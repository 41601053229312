import { ROOT, RootState } from './root.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateInterface } from './interface/state.interface';

export const selectRoot = createFeatureSelector<RootState, StateInterface>(
  ROOT
);

export const selectMicrophone = createSelector(
  selectRoot,
  (state) => state.microphone
);

export const selectErrorMessages = createSelector(
  selectRoot,
  (state) => state.errorMessages
);

export const selectStepTutorial = createSelector(
  selectRoot,
  (state) => state.stepTutorial
);

export const selectCamera = createSelector(selectRoot, (state) => state.camera);

export const selectBrowser = createSelector(
  selectRoot,
  (state) => state.browser
);

export const selectModalStatus = createSelector(
  selectRoot,
  (state) => state.modalStatus
);

export const selectAudioInputDevices = createSelector(
  selectRoot,
  (state) => state.audioInputDevices
);

export const selectAudioOutputDevices = createSelector(
  selectRoot,
  (state) => state.audioOutputDevices
);

export const selectVideoDevices = createSelector(
  selectRoot,
  (state) => state.videoDevices
);

export const selectSo = createSelector(selectRoot, (state) => state.so);

export const selectDataParams = createSelector(
  selectRoot,
  (state) => state.dataParams
);

export const selectGroupRooms = createSelector(
  selectRoot,
  (state) => state.groupRooms
);

export const selectIsTest = createSelector(selectRoot, (state) => state.isTest);

export const selectToken = createSelector(
  selectDataParams,
  (state) => state.token
);

export const selectRoomType = createSelector(
  selectDataParams,
  (state) => state.roomType
);

export const selectRoomMgt = createSelector(
  selectDataParams,
  (state) => state.roommgt
);

export const selectRoom = createSelector(
  selectDataParams,
  (state) => state.room
);

export const selectMeetingRoom = createSelector(
  selectDataParams,
  (state) => state.meetingUrl
);

export const selectIdRoom = createSelector(selectRoot, (state) => state.idRoom);

export const selectShowError = createSelector(
  selectRoot,
  (state) => state.showError
);

export const selectShowToast = createSelector(
  selectRoot,
  (state) => state.toast
);

export const selectDetailOfRoom = createSelector(
  selectRoot,
  (state) => state.detailOfRoom
);

export const selectStatusRecording = createSelector(
  selectRoot,
  (state) => state.statusRecording
);
