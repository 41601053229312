import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../services/app-config/app-config.service';
import { urls } from '../utils/urls';
import { Constant } from '../utils/constant';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private readonly httpClient: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  login(credentials: {
    username: string;
    password: string;

  }): Observable<boolean> {
    const headers = new HttpHeaders({
    Authorization: Constant.BASIC_AUTH_TOKEN,
    client: this._appConfig.getConfig().client
  });
    return this.httpClient.post<boolean>(
      this._appConfig.getConfig().baseUrl + urls.final.login,
      credentials, { headers }
    );
  }
}
