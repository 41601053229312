import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { JistiApiService } from '../service/jisti-api/jisti-api.service';
import { JibriService } from '../service/jisti-api/jibri.service';
import { Store } from '@ngrx/store';
import { rootAction } from '../../store/root.action';
import { selectStatusRecording } from '../../store/root.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer, interval } from 'rxjs';
import { ToastService } from '../../services/toast/toast.service';
import { ViewContainerRef } from '@angular/core';
import { AppConfigService } from '../../services/app-config/app-config.service';

@Component({
  selector: 'app-videocam',
  templateUrl: './videocam.component.html',
  styleUrls: ['./videocam.component.scss'],
})
export class VideocamComponent implements OnInit, OnDestroy {
  private _unsubscriber$: Subject<void> = new Subject();

  private _roomName: string;
  private _token: string;
  private _subject: string;
  private statusRecording: boolean = false;

  @Input() public domain: string;
  private _initJitsiFlag: boolean;
  @Input() set idRoom(value: string) {
    this._roomName = value;
    this._initJitsi();
  }
  @Input() public width: number;
  @Input() public height: number;
  @Input() public divClass: string;
  @Input() set token(value: string) {
    if (value) {
      this._token = value;
      this._initJitsi();
    }
  }
  @Input() public name: string;
  @Input() public isFirefox: boolean;
  @Input() public isSafari: boolean;
  @Input() groupMeeting: string;
  @Input() set subject(value: string) {
    if (value) {
      this._subject = value;
      this._initJitsi();
    }
  }
  @Input() toolbarButtons: any[];

  @Output() hangUp: EventEmitter<boolean> = new EventEmitter();
  @Output() participantLeft: EventEmitter<boolean> = new EventEmitter();
  @Output() participantJoined: EventEmitter<boolean> = new EventEmitter();

  private isAdmin: boolean;

  showLoading = true;
  div;
  iconRecordingDiv;

  constructor(
    public _jistiService: JistiApiService,
    private renderer: Renderer2,
    private el: ElementRef,
    private jibriService: JibriService,
    private _store: Store,
    private _toastService: ToastService,
    private _view: ViewContainerRef,
    private _appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this._checkIsAdmin();
    this.div = this.renderer.createElement('div');
    this.renderer.addClass(this.div, this.divClass);
    this.renderer.addClass(this.div, 'cnt-video');
    if (this.isAdmin) {
      this.renderer.addClass(this.div, 'admin');
    }
    this.renderer.appendChild(this.el.nativeElement, this.div);

    this.iconRecordingDiv = this.renderer.createElement('div');

    if (this._appConfig.getConfig().activeRecording) {
      this.getStatusRecording();
      this.selectStatusRecording();
    }

    this._initJitsi();
    this.showLoading = false;
  }

  private _checkIsAdmin(): boolean {
    return window.location.href.indexOf('admin') !== -1;
  }

  private _initJitsi(): void {
    this._initJitsiFlag = true;
    this._jistiService?.dispose();
    this._jistiService.initJitsiComponent(
      this.domain,
      this._roomName,
      this.width,
      this.height,
      // this.verifyPermission,
      this.hangUp,
      this.participantLeft,
      this.participantJoined,
      this.div,
      this._token,
      this.isFirefox,
      this.isSafari,
      this.groupMeeting,
      this.name,
      this._subject,
      this.toolbarButtons
    );
  }

  private getStatusRecording() {
    interval(10000).pipe(takeUntil(this._unsubscriber$)).subscribe(() => {
      this._store.dispatch(rootAction.fetchStatusRecording({room: this._roomName}));
    });
  }

  private selectStatusRecording() {
    this._store.select(selectStatusRecording).pipe(takeUntil(this._unsubscriber$)).subscribe(res => {
      if (this.statusRecording != res) {
        this.statusRecording = res;
        if (this.statusRecording) {
          this.showIconRecording();
        } else {
          this.removeIconRecording();
        }
      }
    });
  }

  private showIconRecording() {
    this.renderer.addClass(this.iconRecordingDiv, 'red-cam');
    this.iconRecordingDiv.innerHTML = 'REC';
    this.renderer.appendChild(this.div, this.iconRecordingDiv);
    this._toastService.show('initRecording', 'warning', true, this._view);
  }

  private removeIconRecording() {
    this.renderer.removeChild(this.div, this.iconRecordingDiv);
    this._toastService.show('stopRecording', 'warning', true, this._view);
  }

  ngOnDestroy(): void {
    this.renderer.destroy();
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }
}
