import { createAction, props } from '@ngrx/store';
import { PermissionInterface } from './interface/permission.interface';
import { DataParamsInterface } from './interface/data-params.interface';
import { ToastInterface } from './interface/toast.interface';
import { DetailOfRoomInterface } from '../interfaces/detail-of-room.interface';

const setPermisionCamera = createAction(
  '[jisti] changePermision Camera',
  props<{ permission: PermissionInterface }>()
);

const setPermisionMicrophone = createAction(
  '[jisti] changePermision Microphone',
  props<{ permission: PermissionInterface }>()
);

const setIsTest = createAction(
  '[jisti] setIsTest',
  props<{ isTest: boolean }>()
);

const setBrowserName = createAction(
  '[jisti] get Browser name',
  props<{ browser: string }>()
);

const setModalStatus = createAction(
  '[jisti] set ModalStatus',
  props<{ modalStatus: boolean }>()
);

const setSo = createAction('[jisti] set ModalStatus', props<{ so: string }>());

const fetchDataParams = createAction(
  '[jisti] fetchDataParams',
  props<{ params: string }>()
);

const fetchGroupRooms = createAction(
  '[jisti] fetchGroupRooms',
  props<{ room: string }>()
);

const setGroupRooms = createAction(
  '[jisti] setGroupRooms',
  props<{ roomData: DataParamsInterface[] }>()
);

const setDataParams = createAction(
  '[jisti] setDataParams',
  props<{ dataParams: DataParamsInterface }>()
);

const setAudioInputDevices = createAction(
  '[jisti] set audioInputDevices',
  props<{ audioInputDevices: [] | MediaDeviceInfo[] }>()
);

const setAudioOutputDevices = createAction(
  '[jisti] set audioOutputDevices',
  props<{ audioOutputDevices: [] | MediaDeviceInfo[] }>()
);

const setVideoDevices = createAction(
  '[jisti] set videoDevices',
  props<{ videoDevices: [] | MediaDeviceInfo[] }>()
);

const setIdRoom = createAction(
  '[jisti] set idRoom',
  props<{ idRoom: string }>()
);

const setErrors = createAction(
  '[jisti] set errors',
  props<{ errors: string[] }>()
);

const setStepTutorial = createAction(
  '[jisti] set stepTutorial',
  props<{ stepTutorial: number }>()
);

const setShowError = createAction(
  '[jisti] set showError',
  props<{ showError: boolean }>()
);

const showToast = createAction(
  '[jisti] showToast',
  props<{ toast: ToastInterface }>()
);

const setDetailOfRoom = createAction(
  '[jisti] set DetailOfRoom',
  props<{ detailOfRoom: DetailOfRoomInterface }>()
);

const fetchStatusRecording = createAction(
  '[jisti] fetchStatusRecording',
  props<{ room: string }>()
);

const setStatusRecording = createAction(
  '[jisti] setStatusRecording',
  props<{ statusRecording: boolean }>()
);

export const rootAction = {
  setPermisionCamera,
  setPermisionMicrophone,
  setBrowserName,
  setModalStatus,
  setAudioInputDevices,
  setAudioOutputDevices,
  setErrors,
  setVideoDevices,
  setSo,
  fetchDataParams,
  setDataParams,
  setIdRoom,
  setStepTutorial,
  setShowError,
  showToast,
  setDetailOfRoom,
  fetchGroupRooms,
  setGroupRooms,
  setIsTest,
  fetchStatusRecording,
  setStatusRecording,
};
