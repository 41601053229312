import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, filter } from 'rxjs/operators';
import { GetConfigurationService } from '../services/get-configuration/get-configuration.service';
import { rootAction } from './root.action';
import { throwError, Subject } from 'rxjs';
import { DataParamsInterface } from './interface/data-params.interface';
import { SpinnerService } from '../services/spinner/spinner.service';
import { IsGroupalService } from '../services/is-groupal/is-groupal.service';
import { JibriService } from '../components/service/jisti-api/jibri.service';
import { Store } from '@ngrx/store';
import { selectToken } from './root.selector';

@Injectable()
export class RootEffect {
  private _unsubscriber$: Subject<void> = new Subject();

  constructor(
    private actions$: Actions,
    private _configurationService: GetConfigurationService,
    private _isGroupalService: IsGroupalService,
    private _spinnerService: SpinnerService,
    private _jibriService: JibriService,
    private _store: Store
  ) {}

  fetchDataParams = createEffect(() =>
    this.actions$.pipe(
      ofType(rootAction.fetchDataParams),
      switchMap(({ params }) => {
        this._spinnerService.show();
        return this._configurationService.getDataParams(params).pipe(
          map((res: DataParamsInterface) => {
            this._spinnerService.hide();
            if(res.token === '') {
              this._store.select(selectToken).subscribe(token => {
                res.token = token;
                this._unsubscriber$.next();
                this._unsubscriber$.unsubscribe();
              });
            }
            return rootAction.setDataParams({ dataParams: res });
          }),
          catchError((err) => {
            this._spinnerService.hide();
            return throwError(err);
          })
        );
      })
    )
  );

  fetchGroupRooms = createEffect(() =>
    this.actions$.pipe(
      ofType(rootAction.fetchGroupRooms),
      switchMap(({ room }) => {
        this._spinnerService.show();
        return this._isGroupalService.getGroupalRooms$(room).pipe(
          map((res: DataParamsInterface[]) => {
            this._spinnerService.hide();
            return rootAction.setGroupRooms({ roomData: res });
          }),
          catchError((err) => {
            this._spinnerService.hide();
            return throwError(err);
          })
        );
      })
    )
  );

  fetchStatusRecording = createEffect(() =>
    this.actions$.pipe(
      ofType(rootAction.fetchStatusRecording),
      switchMap(({ room }) => {
        return this._jibriService.getStatus(room).pipe(
          map((res: boolean) => {
            return rootAction.setStatusRecording({statusRecording: res});
          }),
          catchError((err) => {
            return throwError(err);
          })
        );
      })
    )
  );
}
