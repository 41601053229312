import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constant } from 'src/app/utils/constant';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../../services/app-config/app-config.service';
import { selectDataParams } from 'src/app/store/root.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataParamsInterface } from 'src/app/store/interface/data-params.interface';
import { rootAction } from 'src/app/store/root.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-browser-not-supported-msg',
  templateUrl: './browser-not-supported-msg.component.html',
  styleUrls: ['./browser-not-supported-msg.component.scss'],
})
export class BrowserNotSupportedMsgComponent implements OnInit, OnDestroy {
  public so: string;
  public url: string;
  public browserUrl: string;
  public downloadUrl: string;
  public logo: string;
  public class: string;
  public idRoomSecure: any;
  public token: any;
  public checkin: any;
  private _unsubscriber$: Subject<void> = new Subject();
  public idRoom: string;
  public iOSText: string;
  public androidText: string;

  constructor(
    private _route: ActivatedRoute,
    private _appConfig: AppConfigService,
    private _store: Store
  ) {
    this.so = '';
    this.url = '';
    this.browserUrl = '';
    this.downloadUrl = '';
    this.logo = '';
    this.class = '';
    this.iOSText = Constant.SO.IOS_TEXT;
    this.androidText = Constant.SO.ANDROID;
  }

  ngOnInit(): void {
    this._store
      .select(selectDataParams)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((res) => {
        this.idRoom = res.room;
        if (document.body.style.background === '') {
          this._fetchDataParams();
        }
      });
    this.so = this._route.snapshot.paramMap.get('so');
    this._getDataParams();

    if (document.body.style.background === '') {
      this._fetchDataParams();
    }
  }

  private _fetchDataParams(): void {
    this._store.dispatch(
      rootAction.fetchDataParams({
        params: this.idRoom,
      })
    );
  }

  private _getDataParams(): void {
    this._store
      .select(selectDataParams)
      .pipe(
        filter((res: DataParamsInterface) => res.data !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((res) => {
        this.idRoomSecure = res.room;
        let token = '';
        if (res.token) {
          token = `?jwt=${res.token}`;
        }
        this.url = 'consultameplexus://' + location.origin + '/' + this.idRoomSecure;
        this.browserUrl = location.origin + '/' + this.idRoomSecure;
        if (this.so === Constant.SO.ANDROID) {
          /* this.class = 'linkAndroid'; */
          this.downloadUrl =
            'https://play.google.com/store/apps/details?id=es.plexus.android.jitsivideocall&hl=es';
          /*            'intent://' +
            this._appConfig.getConfig().jitsiDomain +
            '/' +
            this.idRoomSecure +
            (token ?? '') +
            '#Intent;scheme=org.jitsi.meet;package=org.jitsi.meet;end';*/
        } else {
          /* this.class = 'linkIOS'; */
          this.downloadUrl = 'https://apps.apple.com/app/id1516331388';
          /*this.urlJitsi =
            this._appConfig.getConfig().baseUrl.slice(0, -4) +
            '/' +
            this.idRoomSecure;*/
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  upAppIos() {
    window.open(this.url);
  }
}
