<div class="mails">
  <div *ngFor="let email of invitations.controls; let i = index">
    <div class="mail-input form-row">
      <div class="col-10">
        <label for="emailInvitation{{ i }}">Email:</label>
        <input
          type="email"
          class="form-control email"
          id="emailInvitation{{ i }}"
          required
          [formControl]="getFromControlInvitation(i, 'email')"
        />
      </div>
      <div class="col-1" *ngIf="adminController">
        <label for="adminInvitation{{ i }}">Admin</label>
        <input
          id="adminInvitation{{ i }}"
          class="form-control"
          type="checkbox"
          [formControl]="getFromControlInvitation(i, 'admin')"
          (change)="emitCheckboxStateChange(i, $event.target.checked)"
          (click)="removeOtherAdminsInvitation(i)"
        />
      </div>
      <div class="col-1 remove" *ngIf="invitations.controls.length > 1">
        <span class="material-icons delete" (click)="removeInputInvitation(i)">
          delete
        </span>
      </div>
    </div>
    <div
      *ngIf="
        getFromControlInvitation(i, 'email').invalid &&
        (getFromControlInvitation(i, 'email').dirty ||
          getFromControlInvitation(i, 'email').touched)
      "
      class="alert alert-danger col-11"
    >
      <span class="invalid">{{ 'wrongFormat' | translate }}</span>
      <div
        class="invalid"
        *ngIf="
          getFromControlInvitation(i, 'email').errors &&
          getFromControlInvitation(i, 'email').errors.required
        "
      >
        {{ 'required' | translate }}
      </div>
    </div>
  </div>
  <span class="add" (click)="addInputInvitation()">
    <span class="material-icons">add</span>{{ 'addNew' | translate }}</span
  >
</div>
